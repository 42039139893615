import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import {
	FeaturesSection,
	FeedbackSection,
	HeaderSection,
	MainLayout
	// TestimonialsSection
} from '../components'

export default function IndexPage() {
	return (
		<>
			<MainLayout>
				<HeaderSection className="md:px-0" />
				<main className="container mx-auto">
					<FeaturesSection className="pt-navbar-size md:px-8" />
					{/* <TestimonialsSection className="mx-auto" /> */}
					<FeedbackSection className="pb-28 md:pl-11 pt-32" />
				</main>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
